import axios from "axios";

import qs from "qs";

import { Message } from "element-ui";

let Dev_Server_Api = 'http://192.168.0.58/api/';

let Pre_Server_Api = 'https://t.fanyu.cn/api/';

let Pro_Server_Api = 'https://yoga.fanyu.cn/api/';


let host = window.location.host;

let url;
// if (Pro_Server_Api.indexOf(host) >= 0) {
//   url = Pro_Server_Api;
// } else if (Pre_Server_Api.indexOf(host) >= 0) {
//   url = Pre_Server_Api;
// } else {
//   url = Dev_Server_Api;
// }

// 先判断准确域名是否为测试环境
if (Pre_Server_Api.indexOf(host) >= 0) {
  url = Pre_Server_Api;
} else if (host.indexOf('fanyu') >= 0) {
  // 非准确测试域名，域名带梵羽的一律正式环境
  url = Pro_Server_Api;
} else {
  // 本地
  url = Dev_Server_Api;
}

// create an axios instance
const service = axios.create({
  baseURL: url, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers["App-Id"] = "fanyu_yoga_front/PC/1.0.0/main";
    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    config.data = qs.stringify(config.data, {
      arrayFormat: "repeat"
    });
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);


// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async response => {
    const res = response.data;

    return res;
  },
  error => {
    Message.closeAll();
    if (error.toString() == "Error: timeout of 20000ms exceeded") {
      Message({
        message: "访问超时，请刷新页面重试！",
        type: "error"
      });
    }
    if (error.toString() == "Error: Network Error") {
      Message({
        message: "网络异常，请刷新页面重试！",
        type: "error"
      });
    }
    if (error.response) {
      if (error.response.status == 502 || error.response.status == 503) {
        Message({
          message: "服务器升级，请稍后访问！",
          type: "error"
        });
      } else {
        let baseURL = error.response.config.baseURL;
        let url = error.response.config.url;
        let status = error.response.status;
        let errUrlname = url.replace(baseURL, "");
        Message({
          message:
            (error.response.data &&
              `${error.response.data.message}(${status}--${errUrlname})`) ||
            `请求错误,请刷新页面重试(${status}--${errUrlname})`,
          type: "error", customClass: 'zZindex'
        });
      }
    }
    // MessageBox.alert(error.response && error.response.data.message || '请求错误,请刷新页面重试', "提示", {
    //     dangerouslyUseHTMLString: true
    // });
    console.log(error.toString());
    return Promise.reject((error.response && error.response.data) || error);
  }
);
export default service;
